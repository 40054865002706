import { Global } from "@emotion/react";
import { AppProps } from "next/app";
import Head from "next/head";
import React, { createContext, useEffect, useState } from "react";
import { Provider } from "use-http";

import faviconUrl from "~/assets/favicon.png";
import { SnackLayout } from "~/components/layouts/common/SnackLayout";
import AppStylesProvider from "~/components/providers/AppStylesProvider";
import { API_URL } from "~/constants/api";
import { PEAR_APP_NAME } from "~/constants/app";
import globalStyles from "~/theme/globalStyles";
import { segment } from "~/utils/segment";
import "~/theme/tailwind.css";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pear: any;
  }
}

export const PearContext = createContext<boolean>(false);

function App({ Component, pageProps }: AppProps): JSX.Element {
  const [pearScriptLoaded, setPearScriptLoaded] = useState(false);

  useEffect(() => {
    segment.load({ writeKey: process.env.NEXT_STATIC_SEGMENT_WRITE_KEY || "" });

    const el = document.createElement("script");
    el.onload = () => {
      if (window.pear) {
        setPearScriptLoaded(true);
        if (
          ["localhost", "dev-dashboard.tutor.peardeck.com"].includes(
            window.location.hostname
          )
        ) {
          window.pear.setLogLevel("debug");
        }
        window.pear.identifyProduct(PEAR_APP_NAME);
      }
    };
    el.src = process.env.NEXT_STATIC_PEARDECK_PSI_SCRIPT_URL || "";
    document.head.appendChild(el);
  }, []);

  return (
    <>
      <Head>
        <link rel="icon" href={faviconUrl} />
      </Head>
      <AppStylesProvider>
        <Global styles={globalStyles} />
        <SnackLayout>
          <Provider url={API_URL.replace(/\/$/, "")}>
            <PearContext.Provider value={pearScriptLoaded}>
              <Component {...pageProps} />
            </PearContext.Provider>
          </Provider>
        </SnackLayout>
      </AppStylesProvider>
    </>
  );
}

export default App;
